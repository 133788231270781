/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.style-post > ol {
  @apply list-decimal;
}

.style-post > ul {
  @apply list-disc pl-4 mb-8;
}

.style-post > ul > li,
.style-post > ol > li {
  @apply pb-4 mt-2;
}

.style-post > p {
  @apply pb-4 mt-2;
}

h2 {
  @apply text-xl font-bold text-slate-600 dark:text-slate-600 mb-4;
}

h3 {
  @apply text-xl text-slate-600 dark:text-slate-600 mb-4;
}
